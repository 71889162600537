import axios from 'axios';

import { idDecode, levelDecode } from '../components/common';
import { AllTokenKey, removeToken } from '../util/token';

const getCookie = (name) => {
  return localStorage.getItem(name);
};

let config2 = {
  // 어드민 토큰 (관리자 페이지)
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
    Authorization: 'bearer ' + getCookie('adminToken'),
  },
};
const getSiteLink = window.location.href;

const SOCKET_URL = getSiteLink?.includes('localhost')
  ? process.env.REACT_APP_SOCKET_URL_DEV
  : process.env.REACT_APP_SOCKET_URL;
const API_URL = process.env.REACT_APP_API_URL;
const API_URL_V2 = process.env.REACT_APP_API_URL_V2;
const API_URL_BOT_ALERT = process.env.REACT_APP_API_URL_BOT_ALERT;
const API_URL_BOT_EXIT = process.env.REACT_APP_API_URL_BOT_EXIT;
const API_IMG_URL = process.env.REACT_APP_API_IMG_URL;
const FRANCHISE_URL = process.env.REACT_APP_FRANCHISE_URL;

//로그인 한 유저의 id
// admin:최고관리자
// 6자리: 점주
// 11자리: 유저
let loginId;
const DEFAULT_TIMEOUT = 30000;

const apiWithoutToken = axios.create({ baseURL: `${API_URL}`, timeout: DEFAULT_TIMEOUT });
const apiWithoutTokenV2 = axios.create({ baseURL: `${API_URL_V2}`, timeout: DEFAULT_TIMEOUT });
const socketHealthCheckApiWithoutToken = axios.create({
  baseURL: `${SOCKET_URL}`,
  timeout: DEFAULT_TIMEOUT,
});
const botAlertApiWithoutToken = axios.create({
  baseURL: `${API_URL_BOT_ALERT}`,
  timeout: DEFAULT_TIMEOUT,
});
const botExitApiWithoutToken = axios.create({
  baseURL: `${API_URL_BOT_EXIT}`,
  timeout: DEFAULT_TIMEOUT,
});

export const imageApiWithAdminToken = axios.create({
  baseURL: `${API_IMG_URL}`,
  timeout: DEFAULT_TIMEOUT,
});
export const imageApiWithMerchantToken = axios.create({
  baseURL: `${API_IMG_URL}`,
  timeout: DEFAULT_TIMEOUT,
});

export const franchiseApiWithAdminToken = axios.create({
  baseURL: `${FRANCHISE_URL}`,
  timeout: 5000,
});

export const apiWithMerchantToken = axios.create({
  baseURL: `${API_URL}`,
  timeout: DEFAULT_TIMEOUT,
});
export const apiWithMerchantTokenV2 = axios.create({
  baseURL: `${API_URL_V2}`,
  timeout: DEFAULT_TIMEOUT,
});
export const apiWithAdminToken = axios.create({ baseURL: `${API_URL}`, timeout: DEFAULT_TIMEOUT });
export const apiWithRefreshToken = axios.create({
  baseURL: `${API_URL}`,
  timeout: DEFAULT_TIMEOUT,
});

/**
 * @deprecated Will be removed on next release
 * API 요청 로그 기록
 * @param {*} apiName
 * @param {*} merchant
 * @param {*} data
 * @param {*} api
 * @param {*} notLogin
 * @returns
 */
const frontReqLog = async (apiName, merchant, data, api, notLogin) => {
  return await api;
};

/**
 * @deprecated use login v2 instead
 * 로그인
 *
 * @param {*} id
 * @param {*} pw
 * @returns
 */
export const Login_api = async (id, pw) => {
  let result = await frontReqLog(
    'login',
    'letmeup',
    { id },
    apiWithoutToken.post(`/v1/auth/login`, {
      phoneNum: id,
      password: pw,
    }),
    id,
  );
  if (result.status === 200 && +levelDecode(result.data.access.token) === 99) {
    // 로그인 아이디 저장(최고관리자)
    loginId = 'admin';
    localStorage.setItem('adminToken', result.data?.access.token, {
      path: '/',
      expires: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    });
    localStorage.setItem('adminRefreshToken', result.data?.refresh.token, {
      path: '/',
      expires: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    });
    return result;
  } else if (result.status === 200 && +levelDecode(result.data.access.token) <= 50) {
    // 로그인 아이디 저장
    loginId = id;
    localStorage.setItem('merchantToken', result.data?.access.token, {
      path: '/',
      expires: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    });
    localStorage.setItem('merchantRefreshToken', result.data?.refresh.token, {
      path: '/',
      expires: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    });
    return result;
  } else {
    return result;
  }
};

/**
 * @deprecated use logout v2 instead
 * @param {*} merchantId
 * @param {*} phoneNum
 * @returns
 */
export const Logout_api = async (merchantId, phoneNum) => {
  if (getCookie('adminToken')) {
    await frontReqLog(
      'logout',
      merchantId,
      { refreshToken: getCookie('adminRefreshToken') },
      apiWithRefreshToken.post(`/v1/auth/logout`, { refreshToken: getCookie('adminRefreshToken') }),
    );
    localStorage.removeItem('level');
    removeToken(AllTokenKey);
  } else {
    await frontReqLog(
      'logout',
      merchantId,
      { refreshToken: getCookie('merchantRefreshToken') },
      apiWithRefreshToken.post(`/v1/auth/logout`, {
        refreshToken: getCookie('merchantRefreshToken'),
      }),
    );
  }
  localStorage.removeItem('number');
  localStorage.clear();
  return window.location.replace(`${process.env.PUBLIC_URL}/auth/login`);
};

export const merchantId_api = async () => {
  try {
    const res = await frontReqLog(
      'merchantUser',
      'merchant',

      {
        token: getCookie('merchantToken'),
      },
      apiWithMerchantToken.post(`/auth/merchantUser`),
    );

    return res;
  } catch (e) {
    try {
      // const result = await frontReqLog(
      //   'refresh-tokens',
      //   'merchant',
      //   {
      //     token: getCookie('merchantRefreshToken'),
      //   },
      //   apiWithRefreshToken.post(`/v1/auth/refresh-tokens`, {
      //     refreshToken: getCookie('merchantRefreshToken'),
      //   }),
      // );
      // localStorage.setItem('merchantToken', result.data.access.token, {
      //   path: '/',
      //   expires: new Date(new Date().setMonth(new Date().getMonth() + 1)),
      // });
      // localStorage.setItem('merchantRefreshToken', result.data.refresh.token, {
      //   path: '/',
      //   expires: new Date(new Date().setMonth(new Date().getMonth() + 1)),
      // });
      // window.location.reload();
    } catch (e) {
      // localStorage.removeItem('merchantToken', {
      //   path: '/',
      // });
      // localStorage.removeItem('merchantRefreshToken', {
      //   path: '/',
      // });
      // window.location.reload();
    }
  }
};

// merchant
export const postTicketCreate = async (data) =>
  await frontReqLog(
    'ticketCreate',
    data.merchantId,
    data,
    apiWithMerchantToken.post(`/ticket/ticketCreate`, data),
  );

// 상품 목록에서 사용
export const getTicketList = async (merchantId) =>
  apiWithoutToken.get(`/ticket/ticketList/${merchantId}`);

export const buyTicketList = async (
  merchantId, // 입장권 구매에서만 사용
) => apiWithMerchantToken.get(`${API_URL_V2}/ticket/ticketItemList/${merchantId}`);

// auth
export const msgSend = async (data) =>
  await frontReqLog(
    'backUserCheck',
    'letmeup',
    {
      phoneNum: data,
    },
    apiWithoutToken.post(`/auth/backUserCheck`, {
      phoneNum: data,
    }),
  );

export const msgAuth = async (number, cert) =>
  await frontReqLog(
    'backUserMsgAuth',
    'letmeup',
    {
      phoneNum: number,
      certNumber: cert,
    },
    apiWithoutToken.post(`/auth/backUserMsgAuth`, {
      phoneNum: number,
      certNumber: cert,
    }),
  );

//안쓰는 api
export const getMerchantList = async () => apiWithoutToken.get(`/user/merchantList`);

export const signUpSite = async (data) =>
  await frontReqLog(
    'signupBack',
    'letmeup',
    data,
    apiWithMerchantToken.post(`/user/signupBack`, data),
  );

export const signUp = async (data) =>
  await frontReqLog('signup', data.merchantId, data, apiWithoutToken.post(`/user/signup`, data));
// Ticket
export const ticketUpdate = async (data) =>
  await frontReqLog(
    'ticketUpdate',
    data[0].merchantId,
    data,
    apiWithMerchantToken.put(`/ticket/ticketUpdate`, data),
  );

export const ticketDelete = async (id, merchantId, type) =>
  await frontReqLog(
    'ticketDelete',
    merchantId,
    { id, merchantId, type },
    apiWithMerchantToken.delete(`/ticket/ticketDelete/${id}/${merchantId}/${type}`),
  );

export const getUserTicketList = async (merchantId, start, end, page, limit, search) =>
  apiWithMerchantToken.get(
    `/ticket/userTicketList/${merchantId}/${start}/${end}/${page}/${limit}/${search}`,
  );

export const ticketExit = async (data) =>
  await frontReqLog(
    'ticketExit',
    data.merchantId,
    data,
    apiWithMerchantToken.put(`/ticket/ticketExit`, data),
  );

export const ticketUsing = async (userId, merchantId) =>
  apiWithoutToken.get(`/ticket/ticketUsing/${userId}/${merchantId}`);

export const allTicketList = async (merchantId, start, end) =>
  apiWithMerchantToken.get(`/ticket/allTicketList/${merchantId}/${start}/${end}`);

//관리자 강제 퇴장

export const ticketForceExit = async (data) =>
  await frontReqLog(
    'ticketForceExit',
    data.merchantId,
    data,
    apiWithMerchantToken.put(`/ticket/ticketForceExit`, data),
  );

export const userTicketEnroll = async (data) =>
  await frontReqLog(
    'userTicketEnroll',
    data.merchantId,
    data,
    apiWithMerchantToken.post(`/ticket/userTicketEnroll`, data),
  );
//안쓰임
export const ticketEnroll = async (data) =>
  await frontReqLog(
    'ticketEnroll',
    data.merchantId,
    data,
    apiWithMerchantToken.post(`/ticket/ticketEnroll`, data),
  );
// Seat
export const getSeatList = async (merchantId) =>
  apiWithMerchantToken.get(`/seat/seatListBack/${merchantId}/seat`);

export const getLockerInfoList = async (merchantId) =>
  apiWithMerchantToken.get(`/seat/seatListBack/${merchantId}/locker`);
export const getSeatLogList = async (merchantId, start, end) =>
  apiWithMerchantToken.get(`/seatlogList/${merchantId}/${start}/${end}`);

export const changeSeat = async (data) =>
  await frontReqLog('seatMove', data.merchantId, data, apiWithoutToken.put(`/seat/seatMove`, data));

export const postCreateSeat = async (data) =>
  await frontReqLog(
    'seatManage',
    data.merchantId,
    data,
    apiWithMerchantToken.post(`/seat/seatManage`, data),
  );

export const deleteSeat = async (id, merchantId) =>
  await frontReqLog(
    'seatManage',
    merchantId,
    { id, merchantId },
    apiWithMerchantToken.delete(`/seat/seatDelete/${id}/${merchantId}`),
  );

export const getLockerList = async (merchantId) =>
  apiWithoutToken.get(`/seat/seatList/${merchantId}/locker`);

export const getMapViewList = async (merchantId) =>
  apiWithMerchantToken.get(`/seat/seatMapView/${merchantId}`);

// Sales
export const getPaylogList = async (merchantId, start, end, page, limit, search) =>
  apiWithMerchantToken.get(`/paylogList/${merchantId}/${start}/${end}/${page}/${limit}/${search}`);

// 결제로그 불러오기(마일리지 적립 및 차감 포함)
export const PaylogDataList = async (merchantId, start, end, page, search) =>
  apiWithMerchantToken.get(
    `/paylogDataList/${merchantId}?pageNum=${page}&start=${start}&end=${end}&phoneNum=${search}`,
  );

export const getPayAllList = async (merchantId, start, end) =>
  apiWithMerchantToken.get(`/paylogAllList/${merchantId}/${start}/${end}`);

export const getSeatAllList = async (merchantId, start, end) =>
  apiWithMerchantToken.get(`/seatlogAllList/${merchantId}/${start}/${end}`);

// Message
export const postSendOneMessage = async (info) =>
  await frontReqLog(
    'sendOneMessage',
    info.mId,
    { info },
    apiWithMerchantToken.post(`/sendOneMessage`, info),
  );

export const postSendManyMessage = async (info) =>
  await frontReqLog(
    'sendManyMessage',
    info.mId,
    { info },
    apiWithMerchantToken.post(`/sendManyMessage`, info),
  );

export const getMessageLog = async (merchantId, start, end, page, limit, search) =>
  apiWithMerchantToken.get(`/msgLog/${merchantId}/${start}/${end}/${page}/${limit}`, {
    params: { search },
  });

// Setting
export const getMerchantInfo = async (merchantId) =>
  apiWithMerchantToken.get(`/user/merchantInfo/${merchantId}`);

export const putSetting = async (info) =>
  await frontReqLog(
    'merchantStaticUpdate',
    info?.merchantId,
    info,
    apiWithMerchantToken.put(`/user/merchantStaticUpdate`, info),
  );

export const putMerchantSetting = async (info) =>
  await frontReqLog(
    'merchantKioskUpdate',
    info?.merchantId,
    info,
    apiWithMerchantToken.put(`/user/merchantKioskUpdate`, info),
  );
export const putPinUpdate = async (info) =>
  await frontReqLog(
    'merchantPinUpdate',
    info?.merchant_uuid,
    '',
    apiWithMerchantToken.put(`/user/merchantPinUpdate`, info),
  );

// user
export const userList = async (merchantId, phoneNum) =>
  apiWithMerchantToken.get(`/user/merchantUserinfo/${merchantId}/${phoneNum}`);

export const userLog = async (merchantId, start, end, page, limit, search, count) =>
  apiWithMerchantToken.get(
    `/userLog/${merchantId}/${start}/${end}/${page}/${limit}/${search}/?count=${count}`,
  );

export const userTicketAccess = async (data) =>
  await frontReqLog(
    'userTicketAccess',
    data?.merchantId,
    data,
    apiWithMerchantToken.put(`/user/userTicketAccess`, data),
  );

export const TicketOverTime = async (data) =>
  await frontReqLog(
    'ticketOverTimeMg',
    data?.merchantId,
    data,
    apiWithMerchantToken.put(`/ticket/ticketOverTimeMg`, data),
  );

export const userTicketUpdate = async (data) =>
  await frontReqLog(
    'userTicketUpdate',
    data?.merchantId,
    data,
    apiWithMerchantToken.put(`/ticket/userTicketUpdate`, data),
  );

export const userTicketBlock = async (data) =>
  await frontReqLog(
    'userTicketBlock',
    data?.merchantId,
    data,
    apiWithMerchantToken.put(`/ticket/userTicketBlock`, data),
  );

export const userBlock = async (data) =>
  await frontReqLog(
    'userBlock',
    data?.merchantId,
    data,
    apiWithMerchantToken.put(`/user/userBlock`, data),
  );

export const userInfoUpdate = async (data) =>
  await frontReqLog(
    'userInfoUpdate',
    data?.merchantId || data?.merchantIdChange,
    data,
    apiWithMerchantToken.put(`/user/userInfoUpdate`, data),
  );

//seminar

export const getSeminarInfoList = async (merchantId) =>
  apiWithMerchantToken.get(`/seat/seatListBack/${merchantId}/seminar`);

export const getSeminarItemList = async (merchantId, start, end) =>
  apiWithMerchantToken.get(`/ticket/seminarList/${merchantId}/${start}/${end}`);

//mileage
export const getMileageLog = async (merchantId, start, end, page, limit, search) =>
  apiWithMerchantToken.get(
    `/mileageLogBack/${merchantId}/${start}/${end}/${page}/${limit}/${search}`,
  );
export const postMileageAdd = async (data) =>
  await frontReqLog(
    'mileageAdd',
    data?.merchantId,
    data,
    apiWithoutToken.post(`/mileageAdd`, data),
  );

//coupon

export const couponEnroll = async (merchantId, data) =>
  await frontReqLog(
    'createCoupon',
    merchantId,
    data,
    apiWithMerchantToken.post(`/coupon/createCoupon/${merchantId}`, data),
  );

export const getCouponData = async (merchantId, start, end, phoneNum, page, limit, isUsed) =>
  apiWithMerchantToken.get(
    `/coupon/showCoupons/${merchantId}/?createdEnd=${end}&createdStart=${start}&phoneNum=${phoneNum}&isUsed=${isUsed}&page=${page}&limit=${limit}`,
  );

export const getCouponIssuedData = async (merchantId, start, end, phoneNum, page, limit) =>
  apiWithMerchantToken.get(
    `/coupon/showCoupons/${merchantId}/?createdEnd=${end}&createdStart=${start}&phoneNum=${phoneNum}&page=${page}&limit=${limit}`,
  );

export const deleteCoupon = async (merchantId, couponId) =>
  await frontReqLog(
    'deleteCoupon',
    merchantId,
    { couponId },
    apiWithMerchantToken.delete(`/coupon/deleteCoupon/${merchantId}/${couponId}`),
  );
//vending:자판기
export const getVendingItemList = async (merchantId) =>
  apiWithoutToken.get(`/vending/vendingItemList/${merchantId}`);

export const updateVendingItemList = async (data) =>
  await frontReqLog(
    'vendingItemUpdate',
    data?.merchantId,
    data,
    apiWithMerchantToken.put(`/vending/vendingItemUpdate`, data),
  );

export const vendingItemDelete = async (merchantId, id) =>
  await frontReqLog(
    'vendingItemDelete',
    merchantId,
    { deletevendingId: id },
    apiWithMerchantToken.delete(`/vending/vendingItemDelete/${merchantId}/${id}`),
  );
export const vendingItemEnroll = async (data) =>
  await frontReqLog(
    'vendingItemEnroll',
    data?.merchantId,
    data,
    apiWithMerchantToken.post(`/vending/vendingItemEnroll/`, data),
  );

/**
 * @deprecated use postAdminMerchant() instead
 * 최고관리자
 * @param {*} data
 * @returns
 */
export const merchantLogin = async (data) =>
  await frontReqLog(
    'merchantLogin',
    data.merchantId,
    data,
    apiWithAdminToken.post(`/v1/auth/merchantLogin`, data),
  );

/**
 * @deprecated will be removed
 * @param {*} data
 * @returns
 */
export const merchantCreate = (data) => apiWithAdminToken.post(`/admin/merchantCreate`, data); // 사용x

export const franchiseCalculate = async (start, end) =>
  franchiseApiWithAdminToken.get(`/franchiseCalculate/${start}/${end}`);

export const merchantMsgAdd = async (data) =>
  await frontReqLog(
    'merchantMsgAdd',
    data.merchantId,
    data,
    apiWithAdminToken.post(`/admin/merchantMsgAdd`, data),
  );
export const getAdminMerchantList = async () =>
  apiWithAdminToken.get(`${API_URL}/admin/merchantListAdmin`);

//payLog
export const postPayLog = async (info) =>
  await frontReqLog('paylog', info.merchantId, info, apiWithoutToken.post(`/paylog`, info));

export const putRefundLog = async (data) =>
  await frontReqLog('refundlog', data.merchantId, data, apiWithoutToken.put(`/refundlog`, data));

//password변경
export const userPassReset = async (data) => {
  if (getCookie('adminToken')) {
    const result = await frontReqLog(
      'userPassReset',
      data.merchantId || 'letmeup',
      data,
      apiWithAdminToken.put(`/auth/userPassReset`, data),
    );
    return result;
  } else {
    const result = await frontReqLog(
      'userPassReset',
      data.merchantId || 'letmeup',
      data,
      apiWithoutToken.put(`/auth/userPassReset`, data),
    );
    return result;
  }
};
// memo
export const createMemo = async (data) =>
  await frontReqLog(
    'memoCreate',
    data.merchantId,
    data,
    apiWithMerchantToken.post(`/memo/memoCreate`, data),
  );

export const getMemo = async (merchantId, phoneNum) =>
  await frontReqLog(
    'memoList',
    merchantId,
    { phoneNum },
    apiWithMerchantToken.get(`/memo/memoList/${merchantId}/${phoneNum}`),
  );

export const deleteMemo = async (merchantId, phoneNum) =>
  await frontReqLog(
    'memoDel',
    merchantId,
    { phoneNum },
    apiWithMerchantToken.delete(`/memo/memoDel/${merchantId}/${phoneNum}`),
  );

// 사용자 api
export const getUserData = async () => {
  try {
    const { data } = await merchantId_api();
    const merchantId = data.id;
    const res = await frontReqLog(
      'userInfo',
      merchantId,
      '',
      apiWithMerchantToken.post(`/user/userInfo`, {
        merchantId,
        token: getCookie('merchantToken'),
      }),
    );
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getSaleData = async (merchantId) =>
  apiWithMerchantToken.get(`/user/merchantInfoUser/${merchantId}`);

export const getWebPayRequest = async (data) =>
  await frontReqLog(
    'webpayRequest',
    data.merchantId,
    data,
    apiWithMerchantToken.post(`/webPayment/webpayRequest`, data),
  );

export const getUserQrCode = async (phoneNum, merchantId) =>
  apiWithMerchantToken.get(`/user/userQrcode/${phoneNum}/${merchantId}`);

//입퇴장로그(비상출입)
export const EntryExitLog = async (
  mid,
  start,
  end,
  page = 1,
  ticketType = null,
  seatNumber = null,
  message = null,
  phoneNum = null,
  ticketId = null,
) => {
  const tickettype = ticketType ? `&ticketType=${ticketType}` : '';
  const seatnumber = seatNumber ? `&seatNumber=${seatNumber}` : '';
  const msg = message ? `&message=${message}` : '';
  const phonenum = phoneNum ? `&phoneNum=${phoneNum}` : '';
  const ticketid = ticketId ? `&ticketId=${ticketId}` : '';

  return apiWithMerchantToken.get(
    `/EntryExitLog/${mid}/${start}/${end}?page=${page}${tickettype}${seatnumber}${msg}${phonenum}${ticketid}`,
  );
};

//백오피스 결제 취소
export const webpayRefund = async (data) =>
  await frontReqLog(
    'webpayRefund',
    data.merchantId,
    data,
    apiWithMerchantToken.post(`/webPayment/webpayRefund`, data),
  );
//백오피스 비밀번호 reset
export const backUserCheckReset = async (data) =>
  await frontReqLog(
    'backUserCheckReset',
    'letmeup',
    data,
    apiWithoutToken.post(`/auth/backUserCheckReset`, data),
  );

//백오피스 비밀번호 reset 메시지 인증
export const backUserMsgAuth = async (data) =>
  await frontReqLog(
    'backUserCheckResetAuth',
    'letmeup',
    data,
    apiWithoutToken.post(`/auth/backUserCheckResetAuth`, data),
  );
export const getUserMerchantList = async (merchantId, search) =>
  apiWithMerchantToken.get(`/user/userMerchantList/${merchantId}/${search}`);

// health-check

export const getHealthCheck = async () => apiWithoutToken.get(`/health-check`);
export const getHealthCheckDB = async () => apiWithoutToken.get(`/health-check-db`);
export const getHealthCheckSocket = async () =>
  socketHealthCheckApiWithoutToken.get(`${SOCKET_URL}/health-check-socket`);

export const getHealthCheckAlertBot = async () =>
  botAlertApiWithoutToken.get(`/health-check-bot-alert`);

export const getHealthCheckExitBot = async () =>
  botExitApiWithoutToken.get(`/health-check-bot-exit`);

// image
export const postBannerImgAdmin = async (data) =>
  await frontReqLog(
    'image-banners-admin',
    'letmeup',
    data,
    imageApiWithAdminToken.post(`/image/banners/admin`, data),
  );
export const postAdImgsAdmin = async (data) =>
  await frontReqLog(
    'image-ads-admin',
    'letmeup',
    data,
    imageApiWithAdminToken.post(`/image/ads/admin`, data),
  );

export const postBannerImg = async (data) =>
  await frontReqLog(
    'image-banners-merchant',
    'letmeup',
    data,
    imageApiWithMerchantToken.post(`/image/banners/merchant`, data),
  );
export const postAdImgs = async (data) =>
  await frontReqLog(
    'image-ads-merchant',
    'letmeup',
    data,
    imageApiWithMerchantToken.post(`/image/ads/merchant`, data),
  );

export const getImages = async (merchantId) => {
  return imageApiWithMerchantToken.get(`/image/merchant/${merchantId}`);
};

export const getImagesAdmin = async () => imageApiWithAdminToken.get(`/image/admin`);

export const deleteImage = async (merchantId, target) => {
  if (merchantId === null)
    return await frontReqLog(
      'image-ads',
      merchantId,
      target,
      imageApiWithAdminToken.delete(`/image/ads/${merchantId}/${target}`),
    );
  return await frontReqLog(
    'image-ads',
    merchantId,
    target,
    imageApiWithMerchantToken.delete(`/image/ads/${merchantId}/${target}`),
  );
};

//공지사항
// 1. 생성
export const announcementCreate = async (data) =>
  await frontReqLog(
    'announcementCreate',
    'letmeup',
    data,
    apiWithAdminToken.post(`/announcement/announcementCreate`, data),
  );
// 2. 출력
export const announcementRead = async (data) => {
  if (data === 'admin') return apiWithAdminToken.get(`/announcement/announcementRead`);
  return apiWithMerchantToken.get(`/announcement/announcementRead`);
};

// 3. 삭제
export const announcementDelete = async (id) =>
  await frontReqLog(
    'announcementDelete',
    'letmeup',
    '',
    apiWithAdminToken.delete(`/announcement/announcementDelete/${id}`),
  ); // 4. 수정
export const announcementUpdate = async (data) =>
  await frontReqLog(
    'announcementUpdate',
    'letmeup',
    data,
    apiWithAdminToken.put(`/announcement/announcementUpdate`, data),
  );
//이용약관
export const getTerms = async () => apiWithoutToken.get(`/kioskTerms/0`);

//메시지 아이템 추가
export const getMsgItemList = async () => apiWithoutToken.get(`/getMsgItemList`);

// 클라이언트키 가져오기
export const msgPointWebpay = async (data) =>
  apiWithMerchantToken.post(`/webPayment/msgPointWebpay`, data);

// 메시지 충전 로그 가져오기
export const getMsgChargeLog = async (startDate, endDate) =>
  apiWithAdminToken.get(`/getMsgChargeLog?start=${startDate}&end=${endDate}`);

export const postMerchantCoupon = async (data) =>
  await frontReqLog(
    'merchantCoupon',
    'letmeup',
    data,
    apiWithAdminToken.post(`/admin/merchantCoupon`, data),
  );

//메시지 보내기(여러명)
export const postSendEveryMessage = async (info) =>
  await frontReqLog(
    'sendEveryMessage',
    info.mId,
    { info },
    apiWithMerchantToken.post(`/sendEveryMessage`, info),
  );

//지점 프랜차이즈 정산
export const franchiseCalculateFunc = async (start, end) =>
  await frontReqLog(
    'franchiseCalculate',
    'admin',
    '',
    franchiseApiWithAdminToken.get(`/franchiseCalculateMail/${start}/${end}`),
  );

export const franchiseCalculateMailSend = async (data) =>
  await frontReqLog(
    'franchiseCalculateMailSend',
    'admin',
    '',
    franchiseApiWithAdminToken.post(`/franchiseCalculateMailSend`, data),
  );

//지점 프랜차이즈 정보 수정
export const franchiseInfoUpdate = async (data) =>
  await frontReqLog(
    'franchiseInfoUpdate',
    'admin',
    '',
    franchiseApiWithAdminToken.patch(`/franchiseInfoUpdate`, data),
  );

// 지점 간단 매출 현황
export const paySimpleLogList = async (merchantId) =>
  apiWithMerchantToken.get(`/paySimplelogList/${merchantId}`);

//hecto 결제시 데이터 가져오는 api(샘랩-메시지)
export const msgPointWebpayData = async (data) =>
  await frontReqLog(
    'msgPointWebpayData',
    data.merchantId,
    '',
    apiWithMerchantToken.post(`/webPayment/msgPointWebpayData`, data),
  );

//환불 test용 api
export const msgPointWebpayRefund = async (data) =>
  apiWithAdminToken.post(`/webPayment/msgPointWebpayRefund`, data);

//결제 데이터
export const webpayData = async (data) =>
  await frontReqLog(
    'webpayData',
    data.merchantId,
    '',
    apiWithMerchantToken.post(`/webPayment/webpayData`, data),
  );

// ticket 현황및 결제 금액 가져오기
export const ticketSales = async (merchantId, startDate, endDate) =>
  apiWithMerchantToken.get(`/ticketSales/${merchantId}/${startDate}/${endDate}`);

// ticket 현황및 결제 금액 가져오기
export const ticketSalesAmount = async (merchantId, startDate, endDate) =>
  apiWithMerchantToken.get(`/ticketSalesAmount/${merchantId}/${startDate}/${endDate}`);

// 결제 관련 정보 출력
export const getPaymentSetting = async (merchantId) =>
  apiWithMerchantTokenV2.get(`/setting/getPaymentSetting/${merchantId}`);

// 결제 관련 정보 저장
export const updatePaymentSetting = async (data) =>
  apiWithMerchantTokenV2.patch(`/setting/updatePaymentSetting`, data);

// 전자영수증 정보 조회
export const getReceipt = async (transactionId) =>
  apiWithoutTokenV2.get(`/payment/getReceipt/${transactionId}`);

// 전자영수증 정보 전달
export const sendReceipt = async (data) =>
  apiWithMerchantTokenV2.post(`/payment/sendReceipt`, data);
